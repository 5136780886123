<template>
  <div>
    <a :href="$baseUrl + `/auth/google/`">
      <!-- <a :href="$baseUrl + `/connect/google/calendar/authenticate_client/`"> -->
      <div class="google-signup-btn">
        <img src="/img/onboarding/google-icon.svg" alt="" />
        <!--  <img
          src="/img/google calendar.svg"
          alt="Sync Your Google Calendar"
          style="width: 30px; margin-left: 10px"
        /> -->
        <div class="google-text">Sign-in with Google</div>
      </div>
    </a>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({}),
  created() {},
  methods: {
    ...mapActions("userManagement", ["setGoogleSignInData"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
a {
  text-decoration: none;
}
</style>
