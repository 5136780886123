var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("a", { attrs: { href: _vm.$baseUrl + "/auth/google/" } }, [_vm._m(0)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "google-signup-btn" }, [
      _c("img", { attrs: { src: "/img/onboarding/google-icon.svg", alt: "" } }),
      _c("div", { staticClass: "google-text" }, [
        _vm._v("Sign-in with Google"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }